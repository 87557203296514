import React from 'react'
import Layout from '../layout'
import { graphql, useStaticQuery } from 'gatsby'
import PhotosetListing from '../components/PhotosetListing'

import SEO from '../components/SEO'

export default ({ data, pageContext }) => {
  useStaticQuery(
    graphql`
      query tagPage($tag: String) {
        allContentfulPhotosets(
          limit: 1000
          sort: { order: DESC, fields: createdAt }
          filter: { tags: { in: [$tag] } }
        ) {
          edges {
            node {
              cover {
                localFile {
                  childImageSharp {
                    fixed(
                      width: 325
                      height: 250
                      quality: 80
                      traceSVG: { background: "#fbfafc", color: "#c9f7ff" }
                    ) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
              }
              id
              slug
              tags
              title
            }
          }
        }
      }
    `
  )

  const posts = data.allContentfulPhotosets
  const { tag, slug } = pageContext

  const metaData = {
    title: `Posts taggeds as ${tag}`,
    slug,
  }

  return (
    <Layout>
      <SEO {...metaData} />
      <div className="container">
        <h1>
          Posts tagged as <u>{tag}</u>
        </h1>
        <PhotosetListing photosets={posts} />
      </div>
    </Layout>
  )

  // return <Layout />
}
